/* 

Theme CSS

1. header -> background
2. arrow -> border

Theme Red:

1. header -> {
  background: red
}

2. arrow -> {
  border: solid #fff
}


*/

/* theme default starts */
._1mUuW {
  background: #fff;
}
._bBYU7 {
  border: solid #000;
}
/* theme default ends */


/* theme red starts */
._3b2pY {
  background: #eb2f06;
}
._3fQ0n {
  border: solid #fff;
}
/* theme red ends */


/* theme blue starts */
._3fNxl {
  background: #3867d6;
}
._16z_f {
  border: solid #fff;
}
/* theme blue ends */

/* theme green starts */
._CLB6y {
  background: #05c46b;
}
._O7ihw {
  border: solid #fff;
}
/* theme green ends */

/* theme dark starts */
._-Ua3q {
  background: #34495e;
}
._28F1u {
  border: solid #fff;
}
/* theme dark ends */

/* theme deepdark starts */
._P6QvD {
  background: #34495e;
}
._1jvha {
  border: solid #fff;
}
/* theme deepdark ends */

._1Op28 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
}
._1GhOp {
  align-self: center;
}
._3fmCl {
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  margin-top: 15px;
  cursor: pointer;
}

._1ydFT { 
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  margin-left: 8px;
}
._1Pw8c { 
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  margin-right: 8px;
}
._2aNF9 {
      font-size: 16px;
      line-height: 1.5;
      padding: 2px;
}
* {
	box-sizing: border-box;
}

._2xcMq {
	max-width: 300px;
	box-shadow: 5px 3px 12px 0px rgba(16, 36, 94, 0.2);
	position: absolute;
	background-color: #fff;
	z-index: 1000 !important;
	font-size: 16px;
	line-height: 1.35;
	color: #000;
}

._1oYOS {
	font-size: 16px;
	line-height: 1.5;
}
._d8Qqp {
	display: flex;
	text-align: center;
	background-color: #fff;
}
._1yGdK {
	flex-basis: 14.2857%;
}
._1hh2I {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
	background-color: #fff;
}
._249_g {
	padding: 10px 7px;
	flex-basis: 42px;
	text-align: center;
}

._3zttl {
	padding: 10px 21px;
}
/* 
Theme CSS
1. hover on null and date -> background color and color
2. today -> background color and color
3. selected date -> background color and color
4. hover on today and selected date -> background color and color


Theme Red:
1. hover on null and date -> {
  background-color: #e6e6e6,
  color:#000
}
2. today -> {
  background-color: #008FFD,
  color:#fff
}
3. selected date -> {
  background-color: #FF0000,
  color:#fff
}
4. hover on today and selected date -> {
  background-color: #FF0000,
  color:#fff
}

Theme White:
1. hover on null and date -> {
  background-color: #e6e6e6,
  color:#000
}
2. today -> {
  background-color: #fff,
  color:#008FFD
}
3. selected date -> {
  background-color: #008FFD,
  color:#fff
}
4. hover on today and selected date -> {
  background-color: #e6e6e6,
  color:#000
}

*/
/* theme default starts*/
._3zVi3:hover,
._1plWg:hover {
	background-color: #e6e6e6;
	color: #000;
}
._WrIdy {
	color: #008ffd;
}
._1ImcB {
	background-color: #008ffd;
	color: #fff;
}

._WrIdy:hover,
._1ImcB:hover {
	background-color: #008ffd;
	color: #fff;
}

._1EpF_ {
	color: #c3c3c3;
}
._1EpF_:hover {
	border-radius: 50%;
	color: #c3c3c3;
	background: #e6e6e6;
	cursor: not-allowed;
}

/* theme default ends */

/* theme red starts*/
._ZCqJx:hover,
._3e-oj:hover {
	background-color: #e6e6e6;
	color: #000;
}

._3vljT {
	color: #eb2f06;
}

._3VNRW {
	background-color: #eb2f06;
	color: #fff;
}

._3vljT:hover,
._3VNRW:hover {
	background-color: #c23616;
	color: #fff;
}

/* theme red ends */

/* theme blue starts*/
._2wmIR:hover,
._3EzT1:hover {
	background-color: #e6e6e6;
	color: #000;
}

._3gFNc {
	color: #3867d6;
}

._2nANS {
	background-color: #3867d6;
	color: #fff;
}

._3gFNc:hover,
._2nANS:hover {
	background-color: #00487c;
	color: #fff;
}
/* theme blue ends */

/* theme green starts*/
._3xYsN:hover,
._2_YcJ:hover {
	background-color: #e6e6e6;
	color: #000;
}

._3SPPg {
	color: #05c46b;
}

._3sNLR {
	background-color: #05c46b;
	color: #fff;
}

._3SPPg:hover,
._3sNLR:hover {
	background-color: #0be881;
	color: #fff;
}
/* theme green ends */

/* theme dark starts*/
._2eP5_:hover,
._24Vzw:hover {
	background-color: #e6e6e6;
	color: #000;
}

._162w5 {
	color: #34495e;
}

._hp0wX {
	background-color: #34495e;
	color: #fff;
}

._162w5:hover,
._hp0wX:hover {
	background-color: #2c3e50;
	color: #fff;
}
/* theme dark ends */

/* theme deepdark starts*/

._3EEI2,
._39dGG {
	color: #fff;
}

._3Rbbx {
	background-color: #34495e;
}

._2ssp5 {
	background-color: #34495e;
	color: #fff;
}
._3EEI2:hover,
._39dGG:hover {
	background-color: #2c3e50;
	color: #fff;
}

._31Ucq {
	background-color: #1c3f61;
	color: #fff;
}

._3KUO- {
	background-color: #2c3e50;
	color: #fff;
}

._31Ucq:hover,
._3KUO-:hover {
	background-color: #2c3e50;
	color: #fff;
}

._3NEDk {
	background-color: #2c3e50;
}

._24nn5 {
	color: #c3c3c3;
}
._24nn5:hover {
	border-radius: 50%;
	color: #c3c3c3;
	background: #34495e !important;
	cursor: not-allowed;
}
/* theme deepdark ends */

/* default theme starts*/

._249_g:hover,
._3zttl:hover {
	cursor: pointer;
}
._1oebL {
	border-radius: 50%;
}
._FsLy_ {
	border-radius: 50%;
}

._1oebL:hover,
._FsLy_:hover {
	cursor: pointer;
}

._3_2T4 {
	color: #c3c3c3;
}
._3_2T4:hover {
	border-radius: 50%;
	color: #c3c3c3;
	background: #e6e6e6;
	cursor: not-allowed;
}

/* default theme ends */
